<template>
  <div class="about pt-8">
    <main-nav></main-nav>
    <br>
    <br>
    <br>
    <h1>This is an about page</h1>
  </div>
</template>


<script>
const mainNav = () => import("@/components/shared/nav.vue");
export default {
  name: 'about',

  components: {
    mainNav
  },

  data: () => ({
    //
  }),
};
</script>
